.content-container__left {
  flex: 1;
  width: 390px;
  display: flex;
  flex-direction: column;
  background-color: #02000e;
  z-index: 2;
  overflow-y: hidden;
  position: relative;
}

.collapsed {
  width: 90px;
  position: relative;
}

.collapsed .apply-dots {
  display: none;
}

.instructions-container {
  flex: 1;
  overflow-y: hidden; /* hide vertical scrollbar on chrome for windows */
}

@media screen and (min-width: 1000px) {
  .content-container__left {
    min-width: 650px;
  }
}

@media screen and (max-width: 1000px) {
  /*On small screen display only tutorial lessons*/
  .content-container__left {
    flex: 1;
    min-width: 100%;
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px;
  font-size: 18px;
  flex-shrink: 0;
}

.light {
  background-color: #FFF !important;
  color: #000 !important;
}

.content-container__left.collapsed .navbar {
  padding-left: 28px;
}

.navbar img {
  width: 25px;
  height: 25px;
  margin-right: 12px;
  position: relative;
}

.navbar .title-container {
  display: inline-block;
}

.navbar .actions-container {
  display: inline-block;
}

.navbar .title-container a {
  text-decoration: none;
  color: #F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Anek Latin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  text-align: center;
  letter-spacing: 0.5px;
}

.navbar .actions-container button {
  background: none;
  border: none;
  padding: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #f4f4f4;
  font-weight: 100;
}

/*Navbar is disabled for big screen*/
@media screen and (min-width: 1560px) {
  .navbar {
    display: none;
  }
}

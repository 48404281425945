.LineNav {
  position: absolute;
  right: .25rem;
  top: .5rem;
  user-select: none;
  margin-left: 20px;
  z-index: 1;
}

.bottom .LineNav {
  top: auto;
  bottom: .5rem;
}

.icon {
  color: #777E8B;
  user-select: none;
  border: 0;
  background: none;
  overflow: hidden;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.25rem;
  padding: 0;
  vertical-align: middle;
  cursor: pointer;
}

.icon:hover {
  /*
  opacity: 0.99 =
    this is a hack because somehow `opacity: 1` sets the
    layer order _below_ a previous element with a lesser
    z-index. No idea why though, suspect specific to chrome.
  */
  opacity: 0.99;
  color: #AFB4BE;
}

.icon.expand {
  padding: 0;
  background-color: transparent;
  margin: 0;
  margin-left: -0.5rem;
  margin-top: 0.4rem;
  background-size: contain;
  height: 8px;
  width: 8px;
}

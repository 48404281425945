.lesson {
  height: 100%;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #f4f4f4;
  box-sizing: border-box;
  letter-spacing: 0.5px;
}

.lesson-content {
  width:  100%;
  height: 90%;
  padding-top: 16px;
  padding-right:  32px;
  padding-left: 32px; /* to align with navbar bars icon */
  overflow-y:  auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.lesson-content.light {
  background-color: #F7F8FC !important;
}

.lesson-content h2 {
  font-size: 32px;
  margin: 0;
  font-weight: 500;
  line-height: 38px;
}

.lesson-content a {
  color: #a9a9a9
}

.lesson-content p {
  line-height: 19px;
  font-size: 16px;
  font-weight: 400;
}

.Filter {
  height: 1rem;
  display: inline-block;
}

.Filter .inner {
  border-radius: 20px;
  display: inline-block;
  padding-right: 1.5rem;
  position: relative;
  left: 1.75rem;
  line-height: 1rem;
  background-color: #ebebeb;
  opacity: 0.95;
}

.error .Filter .inner {
  background: #ebdcdc;
}

.Filter.is-hidden .inner {
  visibility: hidden;
}

.Filter.is-visible .icon {
  visibility: visible;
  opacity: .99;
  color: #AFB4BE;
}

.Filter input {
  font-family: "poppins", "Menlo", consolas, monospace;
  color: #777E8B;
  width: 8rem;
  outline: 0;
  border: 0;
  background: transparent;
  padding: .25rem .5rem;
  font-size: .75rem;
  padding-left: 10px;
}


.Filter input::placeholder {
  opacity: 0.5;
}
.lesson-pager-container {
  display: flex;
  flex-direction: column;
  height: 10%;
}

.pager {
  flex: 1;
  padding: 0 32px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pager .link-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  color: #f4f4f4;
  overflow: hidden;
}

.pager.link-container.light {
  color: #8C0BB7;
}

.link-container.center {
  color: #9a99a1;
  justify-content: center;
}

.link-container .arrow {
  position: relative;
  top: 1px;
}

.pager .link-container a {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}  

.pager .link-container.next a {
  justify-content: flex-end;
}  

.pager .link-container.previous a {
  justify-content: flex-start;
}  

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #F4F4F499;
  display: flex;
}
.progress-done {
  background-color: #1ef9fa;
}
.progress-done.barlight {
  background-color: #8C0BB7 !important;
}
.progress-left {
  flex: 1;
}

.pager .link-container.previous a:hover {
  opacity: .8;
}

.pager .link-container.next a:hover {
  opacity: .8;
}

.pager .link-container.previous .arrow {
  margin-right: 5px;
}

.pager .link-container.next .arrow {
  margin-left: 5px;
}

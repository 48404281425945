.theme-dark,
.theme-dark .Line,
.theme-dark .Input {
  background: #030014;
  color: #abb2bf;
}

.theme-dark.top .Input {
  background: black;
  box-shadow: 0px 1px 1px rgba(146, 145, 145, 0.26);
}

.theme-dark .string.bareString {
  color: #abb2bf;
}

.theme-dark .string.quote:before,
.theme-dark .string.quote:after {
  color: #abb2bf;
}

.theme-dark .Line {
  border-bottom: 1px solid rgb(84, 84, 84);
}

.theme-dark .Input,
.theme-dark .Input textarea,
.theme-dark .prompt.input {
  color: rgb(217, 217, 217);
}

.theme-dark .function.function em,
.theme-dark .Line em {
  color: #c678dd;
}

.theme-dark .number {
  color: #d19a66;
}

.theme-dark .string {
  color: #98c379;
}

.theme-dark .Line .response.error {
  background: rgb(39, 5, 5);
}

.theme-dark a {
  color: #c678dd;
}

.theme-dark .error .Filter .inner {
  background: #38191a;
}

.theme-dark .Filter .inner {
  background-color: #353940;
}

.Line {
  line-height: 1.4rem;
  border-bottom: 1px solid #eee;
  position: relative;
  background: white;
  overflow: hidden;
}

.Line .input,
.Line .output {
  display: flex;
  max-width: 100%;
  position: relative;
  padding: 0.6rem;
  padding-left: 1.4rem;
}

.Line .input {
  white-space: pre;
}

.Line .input.prompt {
  white-space: pre-wrap;
  overflow-x: auto;
}

.Line .prompt:before {
  position: absolute;
  content: '';
  background-size: contain;
  top: 0.8rem;
  left: 0.4rem;
  width: 12px;
  height: 12px;
  /*scale: 0.75 0.75;*/
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.Line .input:before {
  background-image: url(../assets/in.svg);
  top: 0.9rem;
}

.Line .prompt.error {
  background: #FFEFEF;
}

.Line .output:before {
  background-image: url(../assets/out.svg);
}

.Line .output.log:before {
  background-image: none;
}

.Line .error.output:before {
  background-image: url(../assets/error.svg);
}

.Line:hover .LineNav,
.Line:focus .LineNav {
  display: block;
}

.Line.out:before {
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  height: calc(100% - 2rem);
  background: blue;
  left: 0.5rem;
}

.Line em {
  user-select: none;
  padding-right: 5px;
  cursor: pointer;
  color: #2196F3;
}

.Line .error em,
.Line .error .bareString {
  color: #F44336;
}

.Line .sep {
  padding-right: 1ch;
}

.Line .type em:hover {
  text-decoration: underline;
}

.ArrayType.closed {
  cursor: pointer;
}

.Line .type.closed * em:hover {
  text-decoration: none;
}

.ArrayType.closed > div {
  display: inline-block;
}

.group {
  margin-left: 0.5rem;
  /*margin-top: 0.5rem;*/
}

.key-value {
  line-height: 1.2rem;
  white-space: nowrap;
  display: flex;
}

.closed .key-value {
  display: inline;
}

.index {
  user-select: none;
  vertical-align: text-bottom;
}

.key, .index {
  line-height: 1.2rem;
  color: #979797;
  margin-right: .5rem;
}


.arb-info {
  color: #999;
}

.Line > .output > .type {
  overflow-x: auto;
}

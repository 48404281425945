#sideMenu {
  flex: none;
  padding: 32px 0 0 32px;
  background-color: #030014;
  width: 280px;
  box-sizing: border-box;
  position: static;
  left: 0px;
  top: 0px;
  transition: transform 0.4s cubic-bezier(0, 0.52, 0, 1);
  overflow-y: auto;
}

#sideMenu .light {
  background-color: #FFF;
}

.menu-title {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  font-size: 18px;
  display: inline-block;
}

.menu-title img {
  width: 25px;
  height: 25px;
  margin-right: 12px;
  position: relative;
}

.menu-title a {
  text-decoration: none;
  color: #F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Anek Latin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  text-align: center;
  letter-spacing: 0.5px;
}

#sideMenu Link {
  transform: translate3d(-450px, 0, 0);
}

#sideMenu.hide {
  transition: all 0.3s;
  transform: translateX(-100%);
}

#sideMenu.show {
  transition: all 0.3s;
  transform: translateX(0);
}

#sideMenu button {
  font-size: 29px; /* to increase the width of the X icon inside to 18px */
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 11px;
  cursor: pointer;
  outline: inherit;
  color: #f4f4f4;
  font-weight: 100;
}

#sideMenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#sideMenu li {
  line-height: 21px;
  padding-top: 0;
  padding-bottom: 16px;
  font-size: 16px;
  position: relative;
  font-weight: 500;
  letter-spacing: 0.5px;
}

#sideMenu li.section {
  line-height: 39px;
  color: #9a99a1;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 32px;
  font-weight: 500;
}

#sideMenu li a {
  text-decoration: none;
  color: #f4f4f4;
  display: block;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
}

#sideMenu li a.disabled {
  color: #806e6e;
  cursor: default;
}

.backdrop {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.4s;
  z-index: 998;
}

.backdrop.show {
  opacity: 0.5;
  pointer-events: auto;
}

.backdrop.hide {
  opacity: 0;
}

.active {
  position: absolute;
  left: -20px;
}

@media screen and (max-width: 1560px) {
  /*For small screen the Menu has absolute position and is on the top*/
  #sideMenu {
    position: absolute;
    top: 65px;
    z-index: 999;
    height: calc(100% - 65px);
  }
}

@media screen and (min-width: 1560px) {
  /*On bigger screens menu is part of the flexbox and backdrop is disabled*/
  .backdrop {
    display: none;
  }

  /* On bigger screens, the hide has no effect*/
  #sideMenu.hide {
    transform: translateX(0);
  }
}

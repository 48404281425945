body {
  overscroll-behavior: none;
  overflow-x: hidden;
  font-size: 0.9rem;
  background: #030014;
  height: 100%;
}

.main {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.main .nav-container {
  z-index: 100;
  flex-basis: 45px;
  height: 100%;
  width: 100%;
}

.content {
  flex-basis: auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.background {
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 500px;
  height: 300px;
}

.content-container {
  display: flex;
  flex-direction: row;
  background-color: #000;
  height: 100%;
}

.content-container .light {
  background-color: #FFF;
  color: #000;
}

.content-container__right {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
}

.console-container {
  flex: 1;
  overflow-y: auto; /* to hide horizontal scrollbar on chrome for windows */
  z-index: 1;
  background-color: #000;
  color: #ffffff;
  height: 60%;
}

.console-container .light {
  background-color: #FFF;
  color: #000;
}

.mapboxgl-popup-content {
  pointer-events: none !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  pointer-events: none !important;
}


.map-hole {
  height: 100%;
}

.map-container {
  flex: 1;
  overflow: hidden;
}

.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.mapboxgl-ctrl-bottom-left {
  display: none !important;
}

.mapboxgl-ctrl-attrib-inner {
  z-index: -1;
}

.mapboxgl-ctrl-compass {
  display: none !important;
}

.apply-dots .dot.md {
  width: 5.93px;
  height: 5.97px;

}

.apply-dots .dot.lg {
  width: 8.56px;
  height: 8.63px;
}

.mobile-content {
  display: none;
}

.desktop-content {
  display: block;
}

@media screen and (max-width: 1000px) {
  /*On small screen dont display right container with map and console*/
  .content-container__right {
    display: none;
  }

  /*On small screen show mobile content and disable desktop*/
  .mobile-content {
    display: block;
  }

  .desktop-content {
    display: none;
  }
}

/* override jsconsole styles */
.theme-dark {
  background-color: rgba(49, 53, 62, 0.1) !important;
}

.Input {
  background-color: rgba(49, 53, 62, 0.1) !important;
}

.Line {
  background-color: rgba(49, 53, 62, 0.1) !important;
}

.commandline {
  color: #ffffff;
  align-content: normal;
  align-items: normal;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border-block-end-style: none;
  border-block-start-style: none;
  border-bottom-style: none;
  border-inline-start-style: none;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(59, 130, 246, 0.5) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  box-sizing: border-box;
  caret-color: rgb(255, 255, 255);
  cursor: text;
  direction: ltr;
  empty-cells: show;
  fill: rgb(0, 0, 0);
  fill-opacity: 1;
  fill-rule: nonzero;
  flood-color: rgb(255, 255, 255);
  flood-opacity: 1;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 14.4px;
  font-stretch: 100%;
  grid-auto-flow: row;
  height: 40px;
  inline-size: 710.586px;
  justify-content: normal;
  justify-items: normal;
  letter-spacing: normal;
  lighting-color: rgb(255, 255, 255);
  line-height: 24px;
  object-position: 50% 50%;
  outline-color: rgb(0, 0, 0);
  outline-style: none;
  overflow-wrap: break-word;
  padding-block-end: 8px;
  padding-block-start: 8px;
  padding-bottom: 8px;
  padding-inline-end: 8px;
  padding-inline-start: 4px;
  padding-left: 0px;
  padding-right: 8px;
  padding-top: 8px;
  perspective-origin: 355.289px 20px;
  position: static;
  tab-size: 8;
  text-align: start;
  text-anchor: start;
  text-decoration: none solid rgb(0, 0, 0);
  text-decoration-color: rgb(0, 0, 0);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-emphasis-color: rgb(0, 0, 0);
  text-emphasis-position: over;
  text-emphasis-style: none;
  text-overflow: clip;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  text-wrap: wrap;
  vertical-align: middle;
  width: 90%;
  word-break: normal;
  writing-mode: horizontal-tb;
}

body, html, #root {
  height: 100%;
}

.Shell {
  outline: none;
  height: 100%;
  font-size: 0.85rem;
  line-height: 1.4rem;
  font-family: "Menlo", consolas, monospace;
}

.Shell.top {
  padding-top: 2.6rem;
}
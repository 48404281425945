.code-snippet pre {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  background: #353343;
  line-height: 1.6;
  max-width: 100%;
  color: #f4f4f4;
  page-break-inside: avoid;
  padding-left: 1rem;
  padding-right: 1rem;
}

.code-snippet.light pre {
  background-color: #FFF;
  color: #000;
}

.code-snippet.light {
  border-radius: 8px;
  border: 1px solid #E7EAEF;
}

.code-snippet.dark {
  border-radius: 8px;
  border: 1px solid #111111;
  background-color: #353343;
}

.code-snippet code {
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  max-height: fit-content; /* 2 lines * 1.6 line-height */
}

.code-snippet .icon-container {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
}

.code-snippet .icon-container > div {
  align-self: center;
}

.code-snippet button {
  color: #f4f4f4;
  background: none;
  border-style: none;
  padding: 0 10px;
  cursor: pointer;
}

.code-snippet button:hover {
  color: #afb4be;
}

.code-snippet button:active {
  color: #cad1de;
}

@media screen and (max-width: 1000px) {
  /*On small screen disable icons*/
  .code-snippet .icon-container {
    display: none;
  }
}

.Input {
  display: block;
  width: 100%;
  position: relative;
  background: white;
  overflow: hidden;
}

.top .Input {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #E6E8F2;
  /*border-bottom: 1px solid #aaabb8;*/
  box-shadow: 0px 1px 1px rgba(0,0,0,.4);
}

.Input textarea {
  box-sizing: border-box;
}

.Input:before {
  position: absolute;
  content: '';
  background-image: url(../assets/prompt.svg);
  background-size: contain;
  top: 0.9rem;
  left: 0.4rem;
  width: 12px;
  height: 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.Line .input:before {
  background-image: url(../assets/in.svg);
}

.Input .cli,
.Autocomplete {
  resize: none;
  background: none;
  font-family: "Menlo", consolas, monospace;
  border: 0;
  padding: 0.6rem;
  padding-left: 1.4rem;
  display: block;
  width: 100%;
  outline: none;
  font-size: inherit;
  line-height: inherit;
}
